import React from 'react';
import { graphql } from 'gatsby';
import { MenuItem } from '@material-ui/core';
import { Form } from 'react-final-form';
import { makeRequired, makeValidate, TextField, Select } from 'mui-rff';
import * as Yup from 'yup';

import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import ServiceHeading from '../components/layouts/serviceHeading';
import CtaSection from '../components/ctaSection';
import HelpCenterLayout from '../components/layouts/helpCenterLayout';
import FaqAccordion from '../components/faqAccordion';
import ContactSection from '../components/contactSection';
import RechargeNavLinks from '../components/rechargeNavLinks';

import RechargeImage from '../images/bg-llamadas.png';
import EmoteImage from '../images/emotes-calls.png';

const fakeAmounts = [
  { id: 1, title: '7 min Premium', description: '', value: '19.19', previousValue: '22.89' },
  { id: 2, title: '7 min Premium', description: '', value: '19.19', previousValue: '22.89' },
  { id: 3, title: '7 min Premium', description: '', value: '19.19', previousValue: '22.89' },
  { id: 4, title: '7 min Premium', description: '', value: '19.19', previousValue: '22.89' },
];

const faqLeft = [
  {
    title: '¿En qué tiendas puedo recargar personalmente?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo recargar desde cualquier parte del mundo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo sé que le llegó la recarga a mi familiar o amigo?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo mi familiar o amigo se entera que recibió el saldo?',
    description: 'Hello World',
  },
];

const faqRight = [
  {
    title: '¿Recargué un número equivocado, cómo puedo recuperar mi dinero?',
    description: 'Hello World',
  },
  {
    title: '¿Cómo puedo saber cuándo hay promoción de recargas?',
    description: 'Hello World',
  },
  {
    title: 'Mi familiar no recibió la recarga completa, solo el saldo.',
    description: 'Hello World',
  },
  {
    title: '¿Solo puedo recargar a números en Cuba?',
    description: 'Hello World',
  },
  {
    title: '¿Puedo pagar el plan prepago de mi celular en USA?',
    description: 'Hello World',
  },
];

const CubacelRechargePage = () => {
  const schema = Yup.object().shape({
    // Offer validation
    offer: Yup.number().required().min(1, 'Requerido.'),
  });

  const initialValues = {
    offer: 1,
    promoCode: '',
  };

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const onSubmit = (values) => {
    // TODO implementar esto
    console.log(values);
  };

  return (
    <Layout
      headerProps={{ 
        fixedHeaderBg: true,
        disableLogin: true,
        landingOnly: true,
        NavigationOptions: <RechargeNavLinks/>,
      }}
    >
      <SEO title="Llamadas Premium" />

      <ServiceHeading
        image={RechargeImage}
        title="Llamadas Premium"
        subtitle="¡Que bien se oyeee!"
        actionLabel="Recargar ahora"
      >
        <p />

        <Form
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form
              id="rechargeForm"
              noValidate
              onSubmit={handleSubmit}
            >
              <Select
                id="offer"
                key="offer"
                name="offer"
                label="Ofertas"
                fullWidth
                required={required.offer}
                displayEmpty
              >
                {fakeAmounts.map((item, index) => (
                  <MenuItem key={`amount-${index}`} value={item.id}>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="fw-bold">{item.title}</div>
                        <div className="text-end">
                          <div className="fw-bold text-decoration-line-through text-muted">${item.previousValue}</div>
                          <div className="fw-bold">${item.value}</div>
                        </div>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>

              <TextField
                id="promoCode"
                key="promoCode"
                name="promoCode"
                label="¿Tienes un código promocional?"
                fullWidth
              />
            </form>
          )}
        />
      </ServiceHeading>

      <CtaSection className="mt-md-4 mt-lg-8"/>

      <HelpCenterLayout
        mode="ltr"
        image={EmoteImage}
      >
        <h2 className="text-success mb-4 mb-md-5">¿Cómo llamar a Cuba con Cuballama?</h2>

        <ol className="ol-color">
          <li>Descarga el app de Cuballama para iPhone o Android.</li>
          <li>Si ya tienes una cuenta Cuballama, entra al app con tus credenciales. Si no, crea una nueva.</li>
          <li>Compra saldo Cuballama en la propia app, desde la web o a través de WhatsApp. O si prefieres también puedes comprarlo en cualquiera de las tiendas Cuballama.</li>
          <li>En el menú inferior de la app busca el ícono de teléfono. Crea un contacto nuevo o llama a un contacto de tu lista si ya lo habías añadido anteriormente.</li>
        </ol>
      </HelpCenterLayout>

      <FaqAccordion
        leftCol={faqLeft}
        rightCol={faqRight}
      />

      <ContactSection/>
    </Layout>
  );
};

export default CubacelRechargePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
